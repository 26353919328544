// Variable overrides
// Here you can add other styles

$es-background-color: black;
$es-background-accent: #141623;
$es-background-card: #161d28;
// $es-color: #696a6f;
$es-color: #b1bed1;
$es-light-color: #13151c;

$gray-200__dt: #eaecef;
$gray-400__dt: $es-background-card;
$gray-600__dt: $es-background-card;
$gray-700__dt: $es-background-card;
$body-color__dt: $es-color;

$gray-base__dt: $es-background-color;
$header-bg__dt: $es-background-color;
$subheader-bg__dt: $es-background-color;
$footer-dark-bg__dt: $es-background-color;
$sidebar-bg__dt: $es-background-accent;
$sidebar-minimizer-bg__dt: $es-background-accent;
$sidebar-brand-bg__dt: $es-background-accent;
$sidebar-nav-link-active-bg__dt: $es-background-color;
$sidebar-nav-link-hover-bg__dt: rgba(255, 255, 255, 0.05);
$btn-color: rgba(255, 255, 255, 0.75) !default;
$btn-bg: rgba(68, 152, 236, 0.1) !default;
$border-color: rgba(255, 255, 255, 0.75) !default;
$current-time-color: rgba(255, 255, 255, 0.75) !default;
$today-highlight-bg: rgba(68, 152, 236, 0.1);
$time-selection-bg-color: rgba(68, 152, 236, 0.2);
$calendar-border: rgba(255, 255, 255, 0.75) !default;
$cell-border: rgba(255, 255, 255, 0.75) !default;

$dropdown-color__dt: $es-light-color !default;
$dropdown-bg__dt: $gray-200__dt !default;
$dropdown-border-color__dt: #dfe2e7;
$dropdown-divider-bg__dt: #dfe2e7;
$dropdown-link-color__dt: $es-light-color !default;
$dropdown-link-hover-color__dt: #29b7ff !default;
$dropdown-link-hover-bg__dt: white;
$dropdown-link-disabled-color__dt: rgba($es-light-color, 0.6) !default;
$dropdown-header-color__dt: $es-light-color !default;
$dropdown-color__dt: #13151c;
