// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

@import 'react-big-calendar/lib/sass/styles';

// Import EnduroSat styles
@import '~@endurosat/es-common-javascript-components/lib/index.css';
