@media (min-width: 1024px) {
    .modal-dialog {
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px) and (min-width: 992px) {
    html:not([dir='rtl'])
        .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
        ~ .c-wrapper,
    html:not([dir='rtl'])
        .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
        ~ .c-wrapper {
        margin-left: 200px;
    }
}

@media (max-width: 992px) {
    .c-main {
        padding-top: 100px !important;
    }

    .nav-item:first-child .nav-link {
        padding-left: 2px;
    }

    .nav.nav-tabs .nav-link {
        padding-top: 0;
    }

    .modal-dialog {
        margin: 0 !important;
        height: 100vh !important;
        max-height: 100vh !important;
        width: 100vw !important;
        max-width: 100vw !important;

        .modal-content {
            height: 100vh !important;
            max-height: 100vh !important;
            width: 100vw !important;
        }
    }

    .card-body {
        padding: 0.75rem;
    }
}

.c-sidebar-brand-full {
    padding: 35px;
}
.c-sidebar-brand-minimized {
    padding: 0.2rem;
}
.c-sidebar {
    width: 200px;
    flex: 0 0 200px;
}

.c-main {
    padding-top: 0.5rem;
}

.pretty-scroll {
    position: relative;
    max-height: 40vh;
    overflow-y: hidden;
}

.c-header-toggler .btn {
    border-color: transparent !important;
    box-shadow: 0 0 0 transparent !important;
    color: inherit;
}
.c-header-toggler .btn:focus {
    border-color: transparent !important;
    box-shadow: 0 0 0 transparent;
}

.c-header-toggler .btn:hover {
    border-color: transparent !important;
    box-shadow: 0 0 0 transparent;
    color: inherit;
}

.c-header-brand {
    width: 280px;
    padding-right: 50px;
}

.c-app.c-dark-theme {
    background-color: #000;

    .c-header {
        .c-subheader {
            border: none;
            margin-top: 0;
        }
    }

    .c-sidebar {
        background: linear-gradient(246deg, #161d28 0%, #0b0e12 100%);

        .c-sidebar-minimizer,
        .c-sidebar-brand {
            background: none;
        }

        &:not(.c-sidebar-unfoldable),
        &:hover {
            .c-sidebar-nav-item,
            .c-sidebar-nav-dropdown {
                padding-left: 0.7rem;
                .c-sidebar-nav-link,
                .c-sidebar-nav-dropdown-toggle {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }
        .c-sidebar-nav {
            > .c-sidebar-nav-item,
            > .c-sidebar-nav-dropdown {
                margin-bottom: 0.3rem;

                > .c-sidebar-nav-link,
                > .c-sidebar-nav-dropdown-toggle {
                    padding-left: 1rem;
                }

                .c-sidebar-nav-link,
                .c-sidebar-nav-dropdown-toggle {
                    padding-bottom: 0.4445rem;
                    padding-top: 0.4445rem;
                }
            }
        }
    }

    .c-footer {
        border-top: inherit;
    }

    .card {
        border: none;
        border-radius: 0;
        background: linear-gradient(246deg, #161d28 0%, #0b0e12 100%);
    }
    .card-with-header {
        height: calc(100% - 44px);
    }
    .card-body {
        box-sizing: border-box;
    }
    .card-header {
        background: linear-gradient(246deg, #161d28 0%, #0b0e12 100%);
        border-bottom: 2px solid black;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 500;
        color: white;
        min-height: 44px;
    }
    .card-header.with-close-button {
        padding: 0 0 0 1.25rem;
        div {
            display: inline-block;
            margin-top: 0.75rem;
        }
        button {
            height: 100%;
        }
    }
    .react-grid-item {
        .card {
            height: 100%;
        }
    }

    .modal-dialog {
        max-width: 80vw;

        width: 80vw;

        .modal-content {
            border: none;

            border-radius: 0;

            background: linear-gradient(246deg, #161d28 0%, #0b0e12 100%);

            height: 90vh;

            width: 80vw;

            div._loading_overlay_wrapper {
                display: flex;

                flex-direction: column;
            }

            .modal-header {
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 500;
                color: white;
                border-bottom: 2px solid black;
            }
            .modal-body {
                overflow-y: auto;
            }

            .list-group-item {
                border: none;

                border-top: 1px solid rgba(255, 255, 255, 0.1);

                border-radius: 0;
            }
        }
    }

    .nav.nav-tabs {
        border: none;

        .nav-link {
            border: none;
            background: none;
            color: inherit;
            font-weight: 500;
            line-height: 32px;
            transition: 0.3s;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                margin: 0;
                background: #29b7ff;
                transform: scaleX(1);
                transform-origin: left;
                transition: 0.3s;
                opacity: 0;
            }

            &:hover:after,
            &.active:after {
                opacity: 1;
            }

            &:hover,
            &.active {
                color: #29b7ff;
            }
        }
    }
}

.dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: none;
    top: 10px !important;

    .dropdown-item {
        border: 0;
        height: 50px;
        width: 220px;
        font-weight: 500;
        color: #4f5d73;
        border-bottom: 1px solid #dfe2e7;
        padding-left: 23px;
        text-transform: uppercase;
    }
}

.fluid {
    height: 100%;
}

.no-padding {
    padding: 0;
}

.react-grid-item.react-grid-placeholder {
    background: #4799eb !important;
}

.black {
    background-color: black !important;
}

.height-vh {
    height: 85vh;
}

.c-dark-theme .table-hover tbody tr {
    transition: 0.2s;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #7f7f7f36;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background-color: #4c4c4c;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3b3b3b;
}

.disabled-button {
    cursor: default;
    background-color: lightgray;
}

.clickable {
    cursor: pointer;
}

.clickable.card {
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
}

.expandable-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-subtitle {
    padding: 0 1rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}

a.sidebar-link,
button.sidebar-link {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    background: transparent;
    padding-bottom: 0.4445rem;
    padding-top: 0.4445rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: background 0.3s, color 0.3s;
    &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.05);
    }
}

button.sidebar-link {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

a.sidebar-link {
    padding-left: 2rem;
    transition: 0.3s;
}

a.sidebar-link.active {
    background: rgba(255, 255, 255, 0.1);
    padding-left: 2.5rem;
    transition: 0.3s;
}

.justified-space-between {
    display: flex;
    justify-content: space-between;
}

.justified-center {
    display: flex;
    justify-content: center;
}

.table-filter-select {
    line-height: 1.5;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
}

.gap-5 {
    gap: 5px;
}

//mobile
@media (max-width: 992px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .nav {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .nav,
    .nav-item {
        display: inline-block;
        vertical-align: middle;
    }

    .nav::-webkit-scrollbar {
        display: none;
    }

    .nav-link {
        padding-bottom: 0 !important;
        margin-bottom: 1px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        line-height: 44px !important;
        span {
            padding: 0 10px;
        }
    }

    .tab-content {
        padding-top: 0 !important;
    }

    .modal-body {
        padding: 0;
    }
}

.card-footer {
    display: flex;
    justify-content: flex-end;
}

.c-app .transparent,
.c-dark-theme .transparent,
.c-app.c-dark-theme .transparent {
    background: rgba(255, 255, 255, 0);
}

.modal {
    cursor: default;
}
